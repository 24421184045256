import {MDBCol, MDBIcon, MDBRow} from "mdbreact";
import {Button} from "@material-ui/core";
import React, {useContext} from "react";
import ActionContext from "../../context/action.context";

const HeaderPanel = ({company, handleOpenEdit}) => {
	const {name, siretNum, location} = company;

	const {handleDelete} = useContext(ActionContext)

	return(
		<MDBRow className="card z-depth-0 border border-light mt-3" style={{padding: "2rem", backgroundColor: "inherit", flexWrap: "unset", display: "-webkit-box"}}>
			<MDBCol size="6">
				<h2 style={{fontSize: "1.8rem", marginBottom: "1rem"}}>{name}</h2>
				<MDBRow>
					<MDBCol size="2" style={{display: "inline-grid", color: "#757575"}}>
						<span className="mb-2">Adresse</span>
						<span className="mb-2">Ville</span>
						<span className="mb-2">Code postal</span>
						<span className="mb-2">Siret</span>
					</MDBCol>
					<MDBCol size="6" style={{display: "inline-grid"}}>
						<span className="mb-2">{location.address}</span>
						<span className="mb-2">{location.city}</span>
						<span className="mb-2">{location.postalCode}</span>
						<span className="mb-2">{siretNum}</span>
					</MDBCol>
				</MDBRow>
			</MDBCol>
			<MDBCol size="6">
				<MDBRow className="justify-content-end">
					<Button className="mr-4" color="primary" onClick={handleOpenEdit} disabled={false}>
						<MDBIcon icon="pen-alt" className="mr-2"/>
						Editer
					</Button>

					<Button color="secondary" id="button-delete-company" onClick={handleDelete}>
						<MDBIcon icon="trash-alt" className="mr-2"/>
						Supprimer
					</Button>
				</MDBRow>
			</MDBCol>
		</MDBRow>
	)
}

export default HeaderPanel