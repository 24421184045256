import React, {useContext, useEffect, useState} from 'react';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {MDBSpinner} from "mdbreact";

import firebase from './api/firebase.api.js'

import RoutesWithNavigationApp from "./routes-navigation/RoutesWithNavigationApp";
import LoginPage from "./pages/login.page";
import PromisePage from "./pages/promise.page";

import './assets/css/app.css';
import * as UserModel from "./model/user.model";

import ToastContext from "./context/toast.context";
import UserContext from "./context/user.context"

export default function App() {
    const [user, setUser] = useState(undefined)
    const [organisationRef, setOrganisationRef] = useState(null)
    const location = useLocation()

    const {toast} = useContext(ToastContext)

    useEffect(()=>{
        const unsubscribe = firebase.auth().onAuthStateChanged((userRecord)=>{
            if(userRecord !== null) {
                UserModel.get(userRecord.uid)
                    .then((userData) => {
                        setOrganisationRef(firebase.firestore().collection("Organisation").doc(userData.organisationId))
                        setUser(userData)
                    })
                    .catch((errorMessage) => {
                        toast.fire({icon: "error", title: errorMessage})
                        firebase.auth().signOut();
                    })
            } else {
                setUser(null)
            }
        })
        return () => {
            unsubscribe();
        };
    }, [toast])

    const notConnectedPathname = ["/login", "/verser-ma-taxe-apprentissage"]
    const onlyNotConnectedPathname = ["/login"]

    if(user === undefined)
        return <MDBSpinner/>

    if(user === null && notConnectedPathname.find(path=>location.pathname.includes(path)) === undefined && location.pathname !== "/login")
        return <Redirect to="/login"/>

    if(user !== null && onlyNotConnectedPathname.find(path=>location.pathname.includes(path)) !== undefined)
        return <Redirect to="/dashboard/versement"/>

    return(
        <UserContext.Provider value={{user, organisationRef, setUser}}>
            <Switch>
                <Route path='/login' exact component={LoginPage} />
                <Route path='/verser-ma-taxe-apprentissage/:organisationId' exact component={PromisePage} />
                <Route path='/' component={RoutesWithNavigationApp} />
            </Switch>
        </UserContext.Provider>
    );
}
//<AuthRouteLogin path='/' component={RoutesWithNavigationApp} />