import React, {useContext, useEffect, useState} from "react";
import {useParams} from 'react-router-dom'

import {MDBCol, MDBRow, MDBSpinner} from "mdbreact";

import {error, warningDelete} from "../../utils/Alert";
import ToastContext from "../../context/toast.context";
import ActionContext from "../../context/action.context";
import UserContext from "../../context/user.context";

import * as PromiseModel from '../../model/promise.model'
import * as AffectationModel from "../../model/affectation.model";
import * as CompanyModel from "../../model/company.model";

import HeaderPanel from "../../components/promise-view/header.panel";
import MainPanel from "../../components/promise-view/main.panel";
import CompanyPanel from "../../components/promise-view/company.panel";
import PromiseDialog from "../../components/dialog/promise.dialog";
import ValidationPromiseDialog from "../../components/dialog/validation.promise.dialog";

export default function PromiseView(){
    const { promiseId } = useParams();

    const [openEdit, setOpenEdit] = useState(false)
    const [openValidation, setOpenValidation] = useState(false)

    const [promise, setPromise] = useState(null);
    const [affectation, setAffectation] = useState(null);
    const [company, setCompany] = useState(null);

    const [isPending, setIsPending] = useState(false)

    const {toast} = useContext(ToastContext)
    const {user, organisationRef} = useContext(UserContext)

    useEffect(()=>{
        const unsubscribe = organisationRef
            .collection("Promise")
            .doc(promiseId)
            .onSnapshot((docSnap)=>{
                setPromise(docSnap.data())
            })

        return () => {unsubscribe()}
    },[promiseId, organisationRef])
    useEffect(()=>{
        if(promise === null)
            return

        CompanyModel.get(user.organisationId, promise.companyId).then(setCompany)
        if("affectationId" in promise){
            AffectationModel.get(user.organisationId, promise.affectationId).then(setAffectation)
        }
    }, [promise, user.organisationId])

    const handleEdit = (promiseChanged) => {
        PromiseModel.update(promiseChanged, user.organisationId)
            .then(()=>{
                toast.fire({title: "Modification(s) sauvegardée(s)", icon: "success"})
                setOpenEdit(false)
            })
            .catch(error)
     }
    const handleValid = (date, checkNum) => {
        setIsPending(true)
        let promiseChanged = {...promise, ...{
            state: "paid",
            validatorUid: user.uid,
            dateTimePayment: String(date),
        }}

        if(promise.paymentType === "check"){
            promiseChanged =  {...promiseChanged, checkNum: checkNum};
        }

        PromiseModel.update(promiseChanged, user.organisationId).then(()=>{
            toast.fire({title: "Promesse validée, la génération du reçu libératoire peut prendre quelques secondes", icon: "success"})
            setOpenEdit(false)
        }).catch(error)

        setIsPending(false)
    }
    const handleDelete = () => {
        warningDelete().then(()=>{
            PromiseModel.remove(user.organisationId, promiseId).then(()=>{
                toast.fire({title: promise.state === "pending" ? "Promesse supprimée": "Versement supprimé", icon: "success"});
            })
        }).catch(error)
    }

    if(promise === null || company === null || (("affectionId" in promise) && affectation !== null))
        return (
            <div className="mt-5">
                <MDBSpinner/>
            </div>
        )

    return(
        <ActionContext.Provider value={{handleDelete, handleValid, handleEdit}}>
            <div id="view-ta" className="view-content">
                <MDBRow>
                    <MDBCol size="12">
                        <HeaderPanel
                            setOpenValidation={setOpenValidation}
                            setOpenEdit={setOpenEdit}
                            promise={promise}
                            company={company}
                            isPending={isPending}
                        />
                        <MainPanel
                            promise={promise}
                            affectation={affectation}
                        />
                        <CompanyPanel
                            company={company}
                        />
                    </MDBCol>
                </MDBRow>
            </div>
            <PromiseDialog
                open={openEdit}
                promise={promise}
                handleClose={()=>{setOpenEdit(false)}}
            />
            <ValidationPromiseDialog
                isCheck={promise.paymentType === "check"}
                open={openValidation}
                handleClose={()=>{setOpenValidation(false)}}
            />
        </ActionContext.Provider>
    )
}