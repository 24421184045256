import React, {useContext, useEffect, useState} from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import * as UserModel from '../../model/user.model';

import {error} from "../../utils/Alert";
import ToastContext from "../../context/toast.context";
import ImageInputPreviewer from "../ImageInputPreviewer";

import PreviewImage from "../../assets/img/image-preview.png"
import firebase from "firebase";
import {isEmail} from "../../utils/StringUtils";
import UserContext from "../../context/user.context";
import {MDBCol, MDBIcon, MDBRow} from "mdbreact";

export default function AccountDialog({open, handleClose}) {
	const {user, setUser} = useContext(UserContext)

	const [firstname, setFirstname] = useState(user.firstname)
	const [lastname, setLastname] = useState(user.lastname)
	const [email, setEmail] = useState(firebase.auth().currentUser.email)

	const [password, setPassword] = useState( "")
	const [repassword, setRepassword] = useState("")

	const [signature, setSignature] = useState(PreviewImage)
	const [signatureFile, setSignatureFile] = useState(null)

	const [isPending, setIsPending] = useState(false)

	useEffect(()=>{
		if(!user.permissions.write)
			return;

		firebase.storage().ref(user.signatureStorageRef).getDownloadURL().then(setSignature)
	}, [user])

	const {toast} = useContext(ToastContext);

	const handleSubmit = async () => {
		setIsPending(true)
		try {
			if(email !== firebase.auth().currentUser.email){
				await firebase.auth().currentUser.updateEmail(email)
			}

			if(password !== ""){
				await firebase.auth().currentUser.updatePassword(email)
			}

			await UserModel.update(user.organisationId, user.uid, {
				firstname,
				lastname
			});

			if(signatureFile !== null){
				await firebase.storage().ref(user.signatureStorageRef).put(signatureFile)
			}

			setUser({...user, ...{
				firstname,
				lastname
			}})
			toast.fire({icon: "success", title: "Compte modifiée"})
			handleClose()
		} catch (errorString){
			console.log("error", errorString)
			error(errorString)
		} finally {
			setIsPending(false)
		}

	}

	const isValid = () => {
		const currentUser = firebase.auth().currentUser

		const notEmpty = isEmail(email) && firstname !== "" && lastname !== "";
		const hasBeenChanged = email !== currentUser.email || firstname !== user.firstname || lastname !== user.lastname
			|| (password !== "" && password === repassword) || signatureFile !== null;

		return notEmpty && hasBeenChanged
	}

	return (
		<Dialog
			fullWidth={true}
			maxWidth={"sm"}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">Éditer mon compte</DialogTitle>
			<DialogContent>
				<MDBRow>
					<MDBCol size="6">
						<TextField
							onChange={(event)=>{setFirstname(event.target.value)}}
							value={firstname}
							autoFocus
							margin="dense"
							label="Prénom"
							type="text"
							fullWidth
							className="mb-3"
						/>
					</MDBCol>
					<MDBCol size="6">
						<TextField
							onChange={(event)=>{setLastname(event.target.value)}}
							value={lastname}
							autoFocus
							margin="dense"
							label="Nom"
							type="text"
							fullWidth
							className="mb-3"
						/>
					</MDBCol>
				</MDBRow>
				<TextField
					onChange={(event)=>{setEmail(event.target.value)}}
					value={email}
					autoFocus
					margin="dense"
					label="Adresse e-mail"
					type="email"
					fullWidth
					className="mb-3"
				/>
				<TextField
					onChange={(event)=>{setPassword(event.target.value)}}
					value={password}
					margin="dense"
					label="Mot de passe (laisser le champ vide pour ne pas le changer)"
					type="password"
					fullWidth
				/>
				<TextField
					onChange={(event)=>{setRepassword(event.target.value)}}
					value={repassword}
					margin="dense"
					label="Confirmation du mot de passe"
					type="password"
					fullWidth
				/>
				{user.permissions.write && (
					<ImageInputPreviewer
						handleChangeFile={setSignatureFile}
						handleChangeImage={setSignature}
						text={"Enregistrer une signature"}
						image={signature}
					/>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
				<Button onClick={handleSubmit} color="primary" disabled={!isValid()}>
					Modifier
					{isPending && <MDBIcon icon="spinner" pulse className="ml-1" size="1x" fixed />}
				</Button>
			</DialogActions>
		</Dialog>
	);
}