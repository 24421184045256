import React, {useContext, useRef, useState} from 'react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import CompanyForm from "../form/company.form";
import ActionContext from "../../context/action.context";

export default function CompanyDialog({open, companyProp, handleClose}) {
	const {handleEdit} = useContext(ActionContext)
	const [isValid, setIsValid] = useState(false)
	const companyFormRef = useRef()

	const handleSubmit = async ({company, contactDecision, contactCertificate}) => {
		let companyDataChanged = {...company, ...{
			contactDecision: contactDecision,
			contactCertificate: contactCertificate
		}}

		handleEdit(companyDataChanged)
	}

	const handleIsValid = (isValidLocal) => {setIsValid(isValidLocal)}

	return (
		<Dialog
			fullWidth={true}
			maxWidth={"sm"}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">Editer une entreprise</DialogTitle>
			<DialogContent style={{
				transition: "height .2s ease-in-out",
				maxHeight: "60vh",
				overflowY: "auto",
				paddingRight: "2rem",
				paddingLeft: "2rem"
			}}>
				<CompanyForm
					ref={companyFormRef}
					handleSubmit={handleSubmit}
					companyProp={companyProp}
					contactDecisionProp={companyProp.contactDecision}
					contactCertificateProp={companyProp.contactCertificate}
					handleIsValid={handleIsValid}
					isEdit={true}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
				<Button onClick={() => companyFormRef.current.submit()} disabled={!isValid} color="primary">
					Valider
				</Button>
			</DialogActions>
		</Dialog>
	);
}