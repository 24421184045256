class ObjectUtils {
	static hasLestOneProperty(object, comparator){
		for(const prop in comparator){
			if(object[prop] === comparator[prop])
				return true
		}

		return false
	}

	static equal(object, comparator){
		for(const prop in comparator){
			if(object[prop] !== comparator[prop])
				return false
		}

		return true
	}
}

export default ObjectUtils