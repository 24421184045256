import React, {useContext} from 'react';
import {useHistory} from 'react-router-dom'

import {MDBBadge, MDBIcon} from 'mdbreact';

import {Button, Typography} from "@material-ui/core";

import DoneIcon from "@material-ui/icons/Done";
import TimerIcon from "@material-ui/icons/Timer";
import UserContext from "../../context/user.context";

const options = {year: 'numeric', month: 'long', day: 'numeric'};

export default function PromiseRow({promise}){
    const history = useHistory()

    const showTA = () => {
        history.push(`/promise/view/${promise.promiseId}`, {promise: promise});
    }

    const icon = promise.state === "paid" ?
        <DoneIcon style={{verticalAlign: "sub", color: "#0e6245", fontSize: "1rem", marginLeft: "0.15rem"}}/> :
        <TimerIcon style={{verticalAlign: "sub", color: "orange", fontSize: "1rem", marginLeft: "0.15rem"}}/>;

    return(
        <tr onClick={showTA} className="tr-hover">
            <td className={promise.state === "paid" ? "ta-validate": ""}>
                <MDBBadge color={promise.state === "paid" ? "success" : "warning"} style={{borderRadius: "5px", marginTop: "0.5rem"}} className="z-depth-0">
                    <span style={{marginTop: "2px", fontSize: "1.3em"}}>{promise.state === "paid" ? "Versée" : "Non-versée"}</span> {icon}
                </MDBBadge>
            </td>
            <td>
                <div className="d-block" style={{maxWidth: "15vw"}}>
                    <Typography noWrap={true}>
                        {promise.companyName}
                    </Typography>
                </div>
            </td>
            <td>{promise.amount} €</td>
            <td>{promise.paymentType === "check" ? "Chèque" : "Virement bancaire"}</td>
            <td>{new Date(Number(promise.dateTimeCreation)).toLocaleDateString('fr-FR', options)}</td>
            <ActionTd promise={promise}/>
        </tr>
    );
}

const ActionTd = ({promise}) => {
    const {organisationRef} = useContext(UserContext)

    const handleDelete = () => {
        organisationRef.collection("Promise").doc(promise.promiseId).delete()
    }

    return(
        <td style={{textAlign: "center"}} className="btn-action">
            <Button color="secondary" size="medium" onClick={handleDelete}>
                <MDBIcon icon="trash-alt" className="mr-2"/>
                Supprimer
            </Button>
        </td>
    )
}
