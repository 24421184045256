import firebase from '../api/firebase.api.js'

const auth_code_translate = {
	"auth/invalid-email": "Adresse e-mail invalide",
	"auth/user-disabled": "Utilisateur désactivé",
	"auth/user-not-found": "Aucun utilisateur ne correspond à cette adresse e-mail",
	"auth/wrong-password": "Mot de passe invalide"
}

const get = (uid) => (
	firebase
		.firestore()
		.collectionGroup("User")
		.where("uid", "==", uid)
		.get()
		.then(queries=>queries.docs[0])
		.then(doc=>doc.data())
)

const update = (organisationId, userId, data) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("User")
		.doc(userId)
		.update(data)
)


const login = (email, password) => (
	firebase
		.auth()
		.signInWithEmailAndPassword(email, password)
		.catch(error=>Promise.reject(auth_code_translate[error.code]))
)

export {
	get,
	login,
	update
}