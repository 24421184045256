import React, {useContext, useEffect, useState} from 'react';
import {MDBAnimation, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';

import CompanyRow from "../../components/row/company.row";

import ListUtils from "../../utils/ListUtils";
import FilterSearch from "../../components/FilterSearch";
import FilterSelect from "../../components/FilterSelect";
import UserContext from "../../context/user.context";

export default function CompanyDashboard(){
    const [filterSearch, setFilterSearch] = useState("")
    const [filterState, setFilterState] = useState("All")

    const [companyListDisplay, setCompanyListDisplay] = useState([])
    const [promiseListDisplay, setPromiseListDisplay] = useState([])

    const [companyList, setCompanyList] = useState([])
    const [promiseList, setPromiseList] = useState([])

    const [year] = useState(new Intl.DateTimeFormat("fr-FR", {year: "numeric"}).format(Date.now()))

    const {organisationRef} = useContext(UserContext)

    useEffect(() => {
        organisationRef
            .collection("Company")
            .onSnapshot(async (snap)=>{
                const snapPromise = await organisationRef.collection("Promise").get();

                setPromiseList(snapPromise.docs.map(doc=>doc.data()))
                setCompanyList(snap.docs.map(doc=>doc.data()))
            })
    }, [organisationRef])
    useEffect(()=>{
        if(companyList === [] || promiseList === [])
            return;

        let listCompany = companyList
        let listPromise = promiseList

        if(filterState.includes("state")) {
            let state = filterState.split("-");

            state = state.length > 2 ? `${state[1]}-${state[2]}` : state[1]

            listPromise = ListUtils.filterValue(promiseList, "state", state);
            listCompany = ListUtils.filterValue(companyList, "companyId", promiseList.map(ta => ta.companyId))
        }

        if(filterState.includes("dateTime")) {
            listPromise = promiseList.filter((ta) => {
                const dateTime = new Intl.DateTimeFormat("fr-FR", {year: "numeric"}).format(ta.dateTimeCreation);
                return dateTime === year
            })
            listCompany = ListUtils.filterValue(listCompany, "companyId", listPromise.map(ta=>ta.companyId))

            if(filterState.includes("not")){
                listCompany = companyList.filter(companyElement => !listCompany.includes(companyElement))
            }
        }

        if(filterSearch !== "")
            listCompany = ListUtils.filterSearch(listCompany, "name", filterSearch);

        setPromiseListDisplay(listPromise)
        setCompanyListDisplay(listCompany)
    }, [filterSearch, filterState, companyList, promiseList, year])

    return(
        <div className="dashboard" id="dashboard-company">
            <div className="dashboard-header mb-3">
                <MDBRow>
                    <MDBCol size="6" className="pl-0">
                        <MDBRow>
                            <MDBCol size="7">
                                <FilterSearch
                                    placeholder="Rechercher une entreprise"
                                    value={filterSearch}
                                    handle={setFilterSearch}
                                />
                            </MDBCol>
                            <MDBCol size="5">
                                <FilterSelect
                                    label="Status"
                                    value={filterState}
                                    handle={setFilterState}
                                    options={[
                                        {text: "Tout", value: "All"},
                                        {text: `Promesse enregistrée pour ${year}`, value: `dateTimeCreation-${year}`},
                                        {text: `Pas de promesse enregistrée pour ${year}`, value: `not-dateTimeCreation-${year}`},
                                        {text: `Promesse versée`, value: "state-paid"},
                                        {text: `Promesse non versé`, value: "state-promise"},
                                    ]}
                                />
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
            <div className="dashboard-content card">
                <MDBAnimation type="fadeIn">
                    <MDBTable className="table-head-bold" scrollY={true} maxHeight={"65vh"}>
                        <MDBTableHead>
                            <tr>
                                <th style={{paddingLeft: "2rem"}}>Nom de l'entreprise</th>
                                <th>Contact (decision)</th>
                                <th>Email (decision)</th>
                                <th>Téléphone (decision)</th>
                                <th>Status du dernier versement</th>
                                <th>Montant du dernier versement (€)</th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {companyListDisplay.map((company, index)=>(
                                <CompanyRow
                                    promiseList={promiseListDisplay.filter((ta)=>ta.companyId === company.companyId)}
                                    company={company}
                                    key={index}
                                />
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBAnimation>
            </div>
        </div>
    )
}