import firebase from '../api/firebase.api.js'

const create = (companyData, organisationId) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Company")
		.doc(companyData.companyId)
		.set(companyData)
)

const update = (companyData, organisationId) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Company")
		.doc(companyData.companyId)
		.update(companyData)
)

const get = (organisationId, companyId) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Company")
		.doc(companyId)
		.get()
		.then(doc=>doc.data())
)

const getAll = (organisationId) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Company")
		.get()
)


export {
	create,
	update,
	get,
	getAll
}