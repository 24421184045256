import React, {useContext, useState} from "react";
import {MDBAnimation, MDBCol, MDBContainer, MDBRow} from "mdbreact";

import * as PromiseModel from '../../model/promise.model'
import * as CompanyModel from '../../model/company.model'

import {error} from '../../utils/Alert'

import SiretStep from "../step/siret.step";
import CompanyStep from "../step/company.step";
import PaymentStep from "../step/payment.step";
import ResumeStep from "../step/resume.step";

import {generateUuid} from "../../utils/StringUtils";
import DataContext from "../../context/data.context";

export default function PromiseForm(){
    const [activeStep, setActiveStep] = useState("siret")

    const [company, setCompany] = useState({
        companyId: "",
        name: "",
        location: {
            address: "",
            postalCode: "",
            city: "",
        },
        siretNum: "",
    })
    const [contactDecision, setContactDecision] = useState({
        lastname: "",
        firstname: "",
        position: "",
        email: "",
        phoneNumber: ""
    })
    const [contactCertificate, setContactCertificate] = useState({
        lastname: "",
        firstname: "",
        position: "",
        email: "",
        phoneNumber: ""
    })
    const [promiseInfo, setPromiseInfo] = useState( {
        amount: "",
        paymentType: "bank transfert",
        affectationId: ""
    })

    const {organisation} = useContext(DataContext)

    const handleSubmission = async (promiseInfoProp) => {
        const {organisationId} = organisation
        let promiseData = promiseInfoProp

        let companyData = {...company, ...{contactDecision: contactDecision, contactCertificate: contactCertificate}};
        let isUpdate = company.companyId !== "";

        if(!isUpdate) {
            companyData = {...companyData, companyId: `Company-${generateUuid()}`}
            promiseData = {...promiseData, ...{
                promiseId: `Promise-${generateUuid()}`,
                dateTimeCreation: Date.now(),
                state: "pending",
                companyName: companyData.name
            }}
        }

        promiseData = {...promiseData, companyId: companyData.companyId}

        try {
            if (isUpdate) {
                await CompanyModel.update(companyData, organisationId);
            } else {
                await CompanyModel.create(companyData, organisationId);
            }

            await PromiseModel.create(promiseData, organisationId)

            setPromiseInfo(promiseData)
            setActiveStep("paymentInfo")
        }catch (errorString){
            await error(errorString)
        }
    }

    const handleChangeFormState = (groupName) => (value) => {
        if(groupName === "company"){
            setCompany(value)
            return
        }

        if(groupName === "contactDecision"){
            setContactDecision(value)
            return
        }

        if(groupName === "contactCertificate"){
            setContactCertificate(value)
        }
    }

    return(
        <MDBContainer>
            {activeStep === "siret" ? (
                <SiretStep
                    handleChangeFormState={handleChangeFormState}
                    handleNextStep={()=>{setActiveStep("company")}}
                />
            ) : activeStep === "company" ? (
                <CompanyStepForm
                    handleChangeFormState={handleChangeFormState}
                    companyProp={company}
                    contactDecisionProp={contactDecision}
                    contactCertificateProp={contactCertificate}
                    handleNextStep={()=>{setActiveStep("taInfo")}}
                />
            ) : activeStep === "taInfo" ? (
               <PaymentStepForm
                   promiseInfo={promiseInfo}
                   handleChangeFormState={handleChangeFormState}
                   handleSubmission={handleSubmission}/>
            ) : activeStep === "paymentInfo" ? (
                <ResumeStep
                    promiseInfo={promiseInfo}
                />
            ) : ""}
        </MDBContainer>
    );
}

function CompanyStepForm({handleChangeFormState, companyProp, contactDecisionProp, contactCertificateProp, handleNextStep}){
    return(
        <MDBRow className="justify-content-center">
            <MDBCol md="9" style={{overflow: "auto"}}>
                <MDBAnimation type="fadeIn">
                    <CompanyStep
                        handleChangeFormState={handleChangeFormState}
                        companyProp={companyProp}
                        contactDecisionProp={contactDecisionProp}
                        contactCertificateProp={contactCertificateProp}
                        handleNextStep={handleNextStep}
                        next="taInfo"
                    />
                </MDBAnimation>
            </MDBCol>
        </MDBRow>
    )
}

function PaymentStepForm({promiseInfo, handleChangeFormState, handleSubmission}){
    return(
        <MDBRow className="justify-content-center">
            <MDBCol md="9" style={{overflow: "auto"}}>
                <MDBAnimation type="fadeIn">
                    <PaymentStep
                        handleChangeFormState={handleChangeFormState}
                        promiseInfo={promiseInfo}
                        handleSubmission={handleSubmission}
                    />
                </MDBAnimation>
            </MDBCol>
        </MDBRow>
    )
}
