import firebase from '../api/firebase.api.js'

const create = (affectationData, organisationId) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Affectation")
		.doc(affectationData.affectationId)
		.set(affectationData)
)

const remove = (organisationId, affectationId) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Affectation")
		.doc(affectationId)
		.delete()
)


const update = (affectationData, organisationId) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Affectation")
		.doc(affectationData.affectationId)
		.update(affectationData)
)

const get = (organisationId, affectationId) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Affectation")
		.doc(affectationId)
		.get()
		.then(doc=>doc.data())
)

const get_all = (organisationId) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Affectation")
		.get()
		.then(querySnap=>querySnap.docs.map(doc=>doc.data()))
)

export {
	get,
	get_all,
	create,
	update,
	remove
}