import React from 'react'
import {
	Avatar,
	Card,
	CardContent,
	Typography
} from '@material-ui/core';
import {MDBCol, MDBIcon, MDBRow} from "mdbreact";

const StatsCard = ({title, value, icon, color, valueStyle, cardStyle}) => (
	<Card style={cardStyle}>
		<CardContent style={{paddingBottom: "16px"}}>
			<MDBRow>
				<MDBCol size="8">
					<Typography
						color="textSecondary"
						gutterBottom
						variant="h6"
						style={{fontSize: "1rem"}}
					>
						{title}
					</Typography>
					<Typography
						color="textPrimary"
						variant="h4"
						style={valueStyle}
					>
						{value}
					</Typography>
				</MDBCol>
				<MDBCol size="2">
					<Avatar style={{backgroundColor: color, height: "3vw", width: "3vw"}}>
						<MDBIcon icon={icon} fal={true}/>
					</Avatar>
				</MDBCol>
			</MDBRow>
		</CardContent>
	</Card>
)

export default StatsCard