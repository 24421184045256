import React from "react";
import {FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";

export default function FilterSelect({label, options, handle, value}){

	const id = `input-${label.toLowerCase().replaceAll(" ", "-")}`;
	const labelId = `label-${label.toLowerCase().replaceAll(" ", "-")}`;

	return(
		<FormControl style={{marginTop: "2rem"}}>
			<InputLabel id={labelId}>{label}</InputLabel>
			<Select
				autoWidth={false}
				id={id}
				value={value}
				onChange={(event)=>{handle(event.target.value)}}
				labelId={labelId}
			>
				{options.map((option, index) => (
					<MenuItem value={option.value} key={index}>{option.text}</MenuItem>
				))}
			</Select>
		</FormControl>
	)
}