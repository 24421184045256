import firebase from '../api/firebase.api.js'

const create = (promiseData, organisationId) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Promise")
		.doc(promiseData.promiseId)
		.set(promiseData)
)

const update = (promiseData, organisationId) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Promise")
		.doc(promiseData.promiseId)
		.update(promiseData)
)

const get = (organisationId, promiseId) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Promise")
		.doc(promiseId)
		.get()
		.then(doc=>doc.data())
)

const remove = (organisationId, promiseId) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Promise")
		.doc(promiseId)
		.delete()
)

const getAll = (organisationId) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Promise")
		.get()
)

const getRef = (organisationId, year) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Promise")
		.orderBy("dateTimeCreation", "asc")
		.startAt(new Date(year, 0, 1).getTime())
		.endAt(new Date(year, 11, 31).getTime())
)

export {
	get,
	getRef,
	getAll,
	create,
	update,
	remove
}