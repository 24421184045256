import {MDBCol, MDBRow} from "mdbreact";
import React from "react";

const ContactPanel = ({title, contact}) => {
	return(
		<MDBRow className="card z-depth-0 border border-light mt-3" style={{padding: "2rem", backgroundColor: "inherit", flexWrap: "unset", display: "-webkit-box"}}>
			<MDBCol size="12">
				<h2 style={{fontSize: "1.8rem", marginBottom: "1rem"}}>{title}</h2>
				<MDBRow>
					<MDBCol size="2" style={{display: "inline-grid", color: "#757575"}}>
						<span className="mb-2">Prénom Nom - Fonction</span>
						<span className="mb-2">Adresse Mail</span>
						<span className="mb-2">Numéro de téléphone</span>
					</MDBCol>
					<MDBCol size="6" style={{display: "inline-grid"}}>
						<span className="mb-2">{contact.firstname} {contact.lastname} - {contact.position}</span>
						<span className="mb-2">{contact.email}</span>
						<span className="mb-2">{contact.phoneNumber}</span>
					</MDBCol>
				</MDBRow>
			</MDBCol>
		</MDBRow>
	)
}

export default ContactPanel