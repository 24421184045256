import React, {useContext, useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import {FormControl, FormControlLabel, FormLabel, InputLabel, MenuItem, Radio, RadioGroup, Select} from "@material-ui/core";
import {MDBRow, MDBSpinner} from "mdbreact";

import UserContext from "../../context/user.context";
import ActionContext from "../../context/action.context";

import * as AffectationModel from "../../model/affectation.model";

export default function PromiseDialog({open, promise, handleClose}) {
	const [affectationsList, setAffectationsList] = useState([])

	const [amount, setAmount] = useState(promise.amount)
	const [additionalInfo, setAdditionalInfo] = useState("additionalInfo" in promise ? promise.additionalInfo : "")
	const [paymentType, setPaymentType] = useState(promise.paymentType)
	const [affectationId, setAffectationId] = useState("affectationId" in promise ? promise.affectationId : "")

	const [isValid, setIsValid] = useState(false)

	const {handleEdit} = useContext(ActionContext)
	const {user} = useContext(UserContext)

	useEffect(()=>{
		AffectationModel
			.get_all(user.organisationId)
			.then(setAffectationsList)
	}, [user])

	useEffect(()=>{
		const notEmpty = amount !== ""
		const hasBeenChanged =
			amount !== promise.amount ||
			affectationId !== promise.affectationId ||
			paymentType !== promise.paymentType ||
			additionalInfo !== promise.additionalInfo

		setIsValid(notEmpty && hasBeenChanged)
	}, [amount, paymentType, affectationId, additionalInfo, promise])

	const handleSubmit = () => {
		let promiseChanged = {...promise, ...{
			amount: amount,
			paymentType: paymentType
		}}

		if(additionalInfo !== "")
			promiseChanged = {...promiseChanged, ...{
				additionalInfo: additionalInfo
			}}

		if(affectationId !== "")
			promiseChanged = {...promiseChanged, ...{
				affectationId: affectationId,
			}}

		handleEdit(promiseChanged)
	}

	if(affectationsList === [])
		return <MDBSpinner/>

	const disabled = promise.state === "paid"

	return (
		<Dialog
			fullWidth={true}
			maxWidth={"sm"}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">Editer {promise.state === "paid" ? "un versement": "une promesse de versement"}</DialogTitle>
			<DialogContent>
				<TextField
					autoFocus={true}
					id="input-amount"
					onChange={(event)=>{setAmount(event.target.value)}}
					value={amount}
					disabled={disabled}
					margin="dense"
					label="Montant"
					type="text"
					fullWidth
					className="mb-3"
				/>

				<MDBRow style={{marginTop: "1rem"}} className="mx-0">
					<FormControl component="fieldset">
						<FormLabel component="legend">Moyen de paiement</FormLabel>
						<RadioGroup aria-label="paymentType" name="paymentType" value={paymentType} onChange={(event) => {setPaymentType(event.target.value);}}>
							<FormControlLabel disabled={disabled} value="check" control={<Radio />} label="Chèque" />
							<FormControlLabel disabled={disabled} value="bank_transfert" control={<Radio />} label="Virement bancaire" />
						</RadioGroup>
					</FormControl>
				</MDBRow>

				<MDBRow style={{marginTop: "1rem"}} className="mx-0">
					<FormControl id="assignment-select-div">
						<InputLabel id="assignment-select-label">Affecter le versement</InputLabel>
						<Select
							fullWidth={true}
							id="assignment-select"
							style={{minWidth: "18vw"}}
							value={affectationId}
							onChange={(event)=>{setAffectationId(event.target.value)}}
						>
							{affectationsList.map((affectation, index)=> (
								<MenuItem value={affectation.affectationId} key={index}>
									{affectation.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</MDBRow>

				<MDBRow style={{marginTop: "1rem"}} className="mx-0">
					<TextField
						id="input-additional-info"
						onChange={(event)=>{setAdditionalInfo(event.target.value)}}
						value={additionalInfo}
						margin="dense"
						label="Informations complémentaires"
						type="text"
						fullWidth
						className="mb-3"
					/>
				</MDBRow>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
				<Button onClick={handleSubmit} color="primary" disabled={!isValid}>
					Modifier
				</Button>
			</DialogActions>
		</Dialog>
	);
}