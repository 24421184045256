import {MDBCol, MDBRow} from "mdbreact";
import React from "react";

const convertPaymentText = {
	bank_transfert: "Virement bancaire",
	check: "Chèque"
}

const MainPanel = ({promise, affectation}) => {
	return(
		<MDBRow className="card z-depth-0 border border-light mt-3" style={{padding: "2rem", backgroundColor: "inherit", flexWrap: "unset"}}>
			<h2 style={{fontSize: "1.8rem", marginBottom: "1rem"}}>Détails</h2>
			<MDBRow>
				<MDBCol size="2" style={{display: "inline-grid", color: "#757575"}}>
					<span className="mb-2">Montant</span>
					<span className="mb-2">Moyen de paiement</span>
					<span className="mb-2">ID</span>
					{affectation !== null && <span className="mb-2">Affectation</span>}
				</MDBCol>
				<MDBCol size="3" style={{display: "inline-grid"}}>
					<span className="mb-2">{promise.amount} €</span>
					<span className="mb-2">{convertPaymentText[promise.paymentType]}</span>
					<span className="mb-2">{promise.promiseId}</span>
					{affectation !== null && <span className="mb-2">{affectation.name}</span>}
				</MDBCol>
				{"additionalInfo" in promise && (
					<MDBCol size="4" style={{display: "inline-grid"}}>
                    <span>
                        <u><b>Informations complémentaires</b></u><br/>
                    </span>
						{promise.additionalInfo}
					</MDBCol>
				)}
			</MDBRow>
		</MDBRow>
	)
}

export default MainPanel