import React, {useContext, useEffect, useState} from "react";
import {MDBCol, MDBContainer, MDBRow} from "mdbreact";
import {BarChart, PieChart, Pie, Cell, ResponsiveContainer, Legend, Tooltip, CartesianGrid, YAxis, XAxis, Bar} from 'recharts';

import StatsCard from "../../components/StatsCard";

import UserContext from "../../context/user.context";

import DataContext from "../../context/data.context";
import {getStats, round} from "../../model/stats.model";
import {error} from "../../utils/Alert";

export default function StatsDashboard() {
	const [nbCompanies, setNbCompanies] = useState(0)
	const [nbCompaniesValidate, setNbCompaniesValidate] = useState(0)
	const [nbCompaniesPromise, setNbCompaniesPromise] = useState(0)

	const [totalAmount, setTotalAmount] = useState(0)
	const [totalAmountPaid, setTotalAmountPaid] = useState(0)
	const [totalAmountPending, setTotalAmountPending] = useState(0)

	const [amountDistribution, setAmountDistribution] = useState(null)

	const [retentionRate, setRetentionRate] = useState(0)
	const [newCompanyRate, setNewCompanyRate] = useState(0)

	const [averageAmount, setAverageAmount] = useState(0)

	const {user} = useContext(UserContext)
	const {promiseRef, selectedYear} = useContext(DataContext)

	useEffect(() => {
		getStats(
			user.organisationId,
			promiseRef,
			selectedYear
		).then(({totalAmount, counter, average, promiseDistribution, rate}) => {
			setNbCompanies(counter.company)
			setNbCompaniesValidate(counter.companyPaid)
			setNbCompaniesPromise(counter.companyPending)

			setTotalAmount(totalAmount.global)
			setTotalAmountPending(totalAmount.pending)
			setTotalAmountPaid(totalAmount.paid)

			setAmountDistribution(promiseDistribution)
			setRetentionRate(rate.retention)
			setNewCompanyRate(rate.newCompany)

			setAverageAmount(average)
		}).catch(error)
	}, [selectedYear, user, promiseRef])

	const companyPieChartData = [
		{name: "Nb d'entreprise ayant versée sa taxe", value: nbCompaniesValidate},
		{name: "Nb d'entreprise ayant fait une promesse de versement", value: nbCompaniesPromise},
		{
			name: "Nb d'entreprise n'ayant pas fait de promesse",
			value: nbCompanies - (nbCompaniesValidate + nbCompaniesPromise)
		}
	]
	const amountPieChartData = [
		{name: "Montant collecté", value: totalAmountPaid, label: `${totalAmountPaid} €`},
		{name: "Montant promis", value: totalAmountPending, label: `${totalAmountPending} €`},
	]

	return (
		<div style={{paddingTop: "5vh"}} id="dashboard-stats">
			<MDBContainer>
				<MDBRow center={true}>
					<MDBCol size="5">
						<h3 className="text-center">Entreprises</h3>
						<div style={{width: '100%', height: '90%'}}>
							<CustomPieChart data={companyPieChartData}/>
						</div>
					</MDBCol>
					<MDBCol size="5">
						<h3 className="text-center">Montants</h3>
						<div style={{width: '100%', height: '90%'}}>
							<CustomPieChart data={amountPieChartData} isLabelPrice={true}/>
						</div>
					</MDBCol>
				</MDBRow>
				<MDBRow center={true}>
					<MDBCol size="4">
						<StatsCard
							valueStyle={{fontSize: "1.5rem"}}
							title={"Montant (promis + collecté)"}
							value={`${totalAmount.toLocaleString('fr-FR')} €`}
							icon={"euro-sign"}
							color={"#0F3F62"}
						/>
					</MDBCol>
					<MDBCol size="4">
						<StatsCard
							valueStyle={{fontSize: "1.5rem"}}
							title={"Montant moyen collecté"}
							value={`${averageAmount.toLocaleString('fr-FR')} €`}
							icon={"calculator"}
							color={"#0F3F62"}
						/>
					</MDBCol>
					<MDBCol size="4">
						<StatsCard
							valueStyle={{fontSize: "1.5rem"}}
							title={"Nombre d'entreprise total"}
							value={nbCompanies}
							icon={"briefcase"}
							color={"#0F3F62"}
						/>
					</MDBCol>
				</MDBRow>
				<MDBRow center={true} className="mt-4 pb-5">
					<MDBCol size="8">
						<h3 className="text-center">Proportions des montants par tranche</h3>
						<DistributionBarChart data={amountDistribution}/>
					</MDBCol>
					<MDBCol size="4" className="mt-3">
						<StatsCard
							title={"Taux de fidélisation"}
							value={retentionRate === Number.POSITIVE_INFINITY ? "Non calculable": retentionRate}
							icon={"percent"}
							valueStyle={{fontSize: retentionRate === Number.POSITIVE_INFINITY ? "1.4rem" : "1.5rem"}}
							color={"#0F3F62"}
						/>
						<StatsCard
							cardStyle={{marginTop: "3rem"}}
							title={"Taux de nouveau donateur"}
							value={`${newCompanyRate} %`}
							valueStyle={{fontSize: "1.5rem"}}
							icon={"percent"}
							color={"#0F3F62"}
						/>
					</MDBCol>
				</MDBRow>
			</MDBContainer>
		</div>
	)
}

const CustomPieChart = ({data, isLabelPrice=false}) => {
	const style = {
		marginTop: "1rem",
		top: '65%',
		right: 0,
		transform: 'translate(0, -50%)',
		lineHeight: '24px',
	};

	const priceFormatter = (entry) => `${entry.value.toLocaleString()} €`
	const defaultFormatter = (entry) => entry.value

	const priceRenderTooltipText = (value, name)  => {
		return [`${value} €`, name];
	};
	const defaultRenderTooltipText = (value, name)  => {
		return [value, name];
	};

	return (
		<ResponsiveContainer>
			<PieChart>
				<Pie
					label={isLabelPrice ? priceFormatter : defaultFormatter}
					data={data}
					dataKey="value"
					cx="50%"
					cy="30%"
					innerRadius={60}
					outerRadius={80}
					fill="#8884d8"
				>
					<Cell key={`cell-validate`} fill={"#87c882"}/>
					<Cell key={`cell-not-validate`} fill={"#ee8c64"}/>
					<Cell key={`cell-not-register`} fill={"#a5a5a5"}/>
				</Pie>
				<Tooltip formatter={isLabelPrice ? priceRenderTooltipText : defaultRenderTooltipText}/>
				<Legend
					iconSize={10}
					layout="horizontal"
					verticalAlign="middle"
					wrapperStyle={style}
				/>
			</PieChart>
		</ResponsiveContainer>
	)
}

const DistributionBarChart = ({data}) => {
	const [dataFormatted, setDataFormatted] = useState([])
	const stackedBarFormat = (data) => {
		const {less500, between500and1000, more1000} = data;
		return [
			{
				name: "Moins de 500€",
				paid: !isNaN(less500.paid) ? round(less500.paid * 100) : 0,
				pending: !isNaN(less500.pending) ? round(less500.pending * 100) : 0,
			},
			{
				name: "Entre 500€ et 1000€",
				paid: !isNaN(between500and1000.paid) ? round(between500and1000.paid * 100) : 0,
				pending: !isNaN(between500and1000.pending) ? round(between500and1000.pending * 100) : 0
			},
			{
				name: "Plus de 1000€",
				paid: !isNaN(more1000.paid) ? round(more1000.paid * 100) : 0,
				pending: !isNaN(more1000.pending) ? round(more1000.pending * 100) : 0
			}
		]
	}

	useEffect(()=>{
		if(data === null)
			return;

		setDataFormatted(stackedBarFormat(data))
	}, [data])

	const renderLegendText = (value, entry) => {
		const { color } = entry;
		return <span style={{ color }}>{value === "paid" ? "Versée" : "En attente"}</span>;
	};
	const renderTooltipText = (value, name)  => {
		return [`${value}%`, `${name === "paid" ? "Versée" : "En attente"}`	];
	};

	return(
		<BarChart
			width={600}
			height={450}
			data={dataFormatted}
			margin={{
				top: 20,
				right: 30,
				left: 20,
				bottom: 2,
			}}
		>
			<CartesianGrid strokeDasharray="3 3" />
			<XAxis dataKey="name" />
			<YAxis />
			<Tooltip formatter={renderTooltipText} />
			<Legend verticalAlign="bottom" formatter={renderLegendText} wrapperStyle={{position: "relative"}}/>
			<Bar dataKey="paid" stackId="a" fill="#87c882" barSize={50} />
			<Bar dataKey="pending" stackId="a" fill="#ee8c64" barSize={50} />
		</BarChart>
	)
}