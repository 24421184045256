import React, {useContext, useEffect, useState} from 'react';
import {MDBAnimation, MDBCol, MDBIcon, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import {Button} from "@material-ui/core";

import * as AffectationModel from '../../model/affectation.model'

import ListUtils from "../../utils/ListUtils";

import UserContext from "../../context/user.context";

import '../../assets/css/dashboard.css'

import AffectationRow from "../../components/row/affectation.row";
import AffectationDialog from "../../components/dialog/affectation.dialog";
import FilterSearch from "../../components/FilterSearch";

import ToastContext from "../../context/toast.context";
import ActionContext from "../../context/action.context";
import DataContext from "../../context/data.context";

export default function AffectationDashboard(){
    const [filterSearch, setFilterSearch] = useState("")

    const [affectationsList, setAffectationsList] = useState([])
    const [promiseList, setPromiseList] = useState([])

    const [affectationsListDisplay, setAffectationsListDisplay] = useState([])
    const [addingAffectationDialogFormOpen, setAddingAffectationDialogFormOpen] = useState(false)

    const {user, organisationRef} = useContext(UserContext)
    const {promiseRef} = useContext(DataContext)
    const {toast} = useContext(ToastContext)

    const handleCreate = (affectationsData) => {
        AffectationModel.create(affectationsData, user.organisationId).then(()=>{
            toast.fire({icon: "success", title: "Affectation créée"})
        })
    }
    const handleEdit = (affectationsData) => {
        AffectationModel.update(affectationsData, user.organisationId).then(()=>{
            toast.fire({icon: "success", title: "Affectation modifiée"})
        })
    }
    const handleDelete = (affectationId) => {
        AffectationModel.remove(user.organisationId, affectationId).then(()=>{
            toast.fire({icon: "success", title: "Affectation supprimée"})
        })
    }

    useEffect(()=>{
        organisationRef
            .collection("Affectation")
            .onSnapshot((snap)=>{
                setAffectationsList(snap.docs.map(doc=>doc.data()))
            })

        promiseRef
            .get()
            .then((snap)=>{
                setPromiseList(snap.docs.map(doc=>doc.data()))
            })
    }, [organisationRef, promiseRef])
    useEffect(()=>{
        let list = affectationsList
        if(filterSearch !== "")
            list = ListUtils.filterSearchContain(affectationsList, "name", filterSearch);

        setAffectationsListDisplay(list)
    }, [filterSearch, affectationsList])

    return(
        <ActionContext.Provider value={{handleCreate, handleEdit, handleDelete}}>
            <div className="dashboard" id="dashboard-department">
                <div className="dashboard-header mb-3">
                    <MDBRow>
                        <MDBCol size="4" className="pl-0">
                            <MDBRow>
                                <MDBCol size="12">
                                    <FilterSearch
                                        placeholder={"Rechercher une affectation"}
                                        handle={setFilterSearch}
                                        value={filterSearch}
                                    />
                                </MDBCol>
                            </MDBRow>
                        </MDBCol>
                        <MDBCol size="4" className="justify-content-center">
                            <Button
                                id="button-add-affectation"
                                color="primary"
                                size="large"
                                disabled={!user.permissions.write}
                                onClick={()=>{setAddingAffectationDialogFormOpen(true)}}
                                style={{backgroundColor: "#5469d4", color: "white", marginTop: "1.5rem"}}
                            >
                                <MDBIcon icon="plus" className="mr-2"/>
                                Ajouter une affectation
                            </Button>
                        </MDBCol>
                    </MDBRow>
                </div>
                <div className="dashboard-content card">
                    <MDBAnimation type="fadeIn">
                        <MDBTable className="table-head-bold" scrollY={true} maxHeight={"65vh"}>
                            <MDBTableHead color="primary">
                                <tr>
                                    <th style={{paddingLeft: "2rem"}}>Nom de l'affectation</th>
                                    <th>Nombre de versement récolté</th>
                                    <th>Montant total récolté</th>
                                    <th/>
                                </tr>
                            </MDBTableHead>
                            <MDBTableBody  >
                                {affectationsListDisplay.map((affectation, index)=>(
                                    <AffectationRow
                                        promiseList={
                                            promiseList.filter(
                                                promise=>promise.hasOwnProperty("affectationId") && promise.affectationId === affectation.affectationId
                                            )
                                        }
                                        affectation={affectation}
                                        key={index}
                                    />
                                ))}
                            </MDBTableBody>
                        </MDBTable>
                    </MDBAnimation>
                </div>
            </div>
            <AffectationDialog
                open={addingAffectationDialogFormOpen}
                editMode={false}
                handleClose={()=>{setAddingAffectationDialogFormOpen(false)}}
            />
        </ActionContext.Provider>
    )
}