import {MDBCol, MDBRow} from "mdbreact";
import React from "react";
import ObjectUtils from "../../utils/ObjectUtils";

const CompanyPanel = ({company}) => {
	const {contactDecision, contactCertificate} = company;

	const sameContact = ObjectUtils.equal(contactDecision, contactCertificate);

	return(
		<MDBRow className="card z-depth-0 border border-light mt-3" style={{padding: "2rem", backgroundColor: "inherit", flexWrap: "unset"}}>
			<h2 style={{fontSize: "1.8rem", marginBottom: "1rem"}}>Entreprise</h2>
			<MDBRow>
				<MDBCol size="2" style={{display: "inline-grid", color: "#757575"}}>
					<span className="mb-2">Nom</span>
					<span className="mb-2">ID</span>
					<span className="mb-2">Siret</span>
					<span className="mb-2">Adresse</span>
					<span className="mb-2">Code Postal</span>
					<span className="mb-2">Ville</span>
				</MDBCol>

				<MDBCol size="3" style={{display: "inline-grid"}}>
					<span className="mb-2">{company.name}</span>
					<span className="mb-2">{company.companyId}</span>
					<span className="mb-2">{company.siretNum}</span>
					<span className="mb-2">{company.location.address}</span>
					<span className="mb-2">{company.location.postalCode}</span>
					<span className="mb-2">{company.location.city}</span>
				</MDBCol>

				<MDBCol size="3" style={{display: "inline-grid"}}>
					<u><b>Contact decision {sameContact ? " &  attestation" : ""}</b></u>
					<span className="mb-2">{contactDecision.firstname} {contactDecision.lastname} - {contactDecision.position}</span>
					<span className="mb-2"><a href={`mailto://${contactDecision.email}`}>{contactDecision.email}</a></span>
					<span className="mb-2">{contactDecision.phoneNumber}</span>
				</MDBCol>

				{!sameContact && (
					<MDBCol size="3" style={{display: "inline-grid"}}>
						<u><b>Contact attestation</b></u>
						<span className="mb-2">{contactCertificate.firstname} {contactCertificate.lastname} - {contactCertificate.position}</span>
						<span className="mb-2">{contactCertificate.email}</span>
						<span className="mb-2">{contactCertificate.phoneNumber}</span>
					</MDBCol>
				)}
			</MDBRow>
		</MDBRow>
	)
}

export default CompanyPanel