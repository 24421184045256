import React, {useContext, useEffect, useState} from "react";
import Swal from 'sweetalert2'

import {MDBCol, MDBIcon, MDBRow} from "mdbreact";
import Button from "@material-ui/core/Button";
import {Alert} from '@material-ui/lab';

import firebase from '../../api/firebase.api'

import DataContext from "../../context/data.context";

export default function SiretStep({handleChangeFormState, handleNextStep}){
    const [companyIdentifier, setCompanyIdentifier] = useState("")
    const [isFormatValid, setIsFormatValid] = useState(false)

    const [isPending, setIsPending] = useState(false)
    const [error, setError] = useState("")


    const {organisation} = useContext(DataContext)

    useEffect(()=>{
        setIsFormatValid(companyIdentifier !== "")
    },[companyIdentifier])

    const handleCheckCompanyIdentifier = () => {
        setIsPending(true)
        setError("")

        let checkCompany = firebase
            .app()
            .functions("europe-west1")
            .httpsCallable('checkCompany');

        checkCompany({companyIdentifier: companyIdentifier})
            .then(async (result)=>{
                const {data} = result;
                if("error" in data){
                    setError(data.error)
                    return
                }

                let {companyData, multiple} = data;

                if("contactCertificate" in companyData)
                    handleChangeFormState("contactCertificate")(companyData.contactCertificate)

                if("contactDecision" in companyData)
                    handleChangeFormState("contactDecision")(companyData.contactDecision)

                delete companyData.contactCertificate
                delete companyData.contactDecision

                handleChangeFormState("company")(companyData)

                if(multiple)
                    await Swal.fire({
                        icon: "warning",
                        title: "Plusieurs entreprises trouvées !",
                        text: "Nous avons trouvé plusieurs entreprises correspondant à votre raison sociale, merci de vérifier les informations à la prochaine étape"
                    })

                handleNextStep()
            })
            .catch(()=>{setError("Votre raison sociale ou numéro de SIRET est invalide")})
            .finally(()=>{setIsPending(false)})
    }

    let marginTop = error !== "" ? "10vh" : "15vh";

    return(
        <MDBRow className="justify-content-center" style={{fontFamily: "Montserrat, sans-serif", marginTop: marginTop}}>
            <img src={organisation.logoUrl} alt={`${organisation.name}-logo`} style={{marginBottom: "2rem", width: "30vw", height: "auto"}}/>
            <h5 className={`pt-3 px-5 text-center ${error ? "" : "pb-2"}`} style={{lineHeight: "2rem"}}>
                Faites votre promesse de versement de la Taxe d’apprentissage à {organisation.promiseConfig.organisationText} et recevez votre reçu libératoire une fois le versement validé
            </h5>
            <MDBCol md="6">
                {error !== "" && (
                    <div style={{marginTop: "2rem", marginBottom: "2rem"}}>
                        <Alert variant="outlined" severity="error">
                            {error}
                        </Alert>
                    </div>
                )}
                <label htmlFor="SIRET" className="black-text">
                    <b>Raison sociale ou numéro de SIRET</b>
                </label>

                <input
                    value={companyIdentifier}
                    style={{fontWeight: 500}}
                    type="text"
                    className="form-control"
                    id="input-siret"
                    onChange={(event)=>{setCompanyIdentifier(event.target.value)}}
                />

                <br/>
                <div className="text-center">
                    <Button
                        id="siret-form-button"
                        variant="contained"
                        size="large"
                        color="primary"
                        onClick={handleCheckCompanyIdentifier}
                        disabled={!isFormatValid}
                    >
                        Rechercher {isPending ? (<MDBIcon icon="spinner" className="ml-1" pulse fixed />) : ""}
                    </Button>
                </div>
            </MDBCol>
        </MDBRow>
    )
}