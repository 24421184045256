import React, {useContext, useEffect, useState} from "react";
import {MDBBadge, MDBCol, MDBIcon, MDBRow} from "mdbreact";
import {Button, Fade, Menu, MenuItem} from "@material-ui/core";

import ActionContext from "../../context/action.context";
import UserContext from "../../context/user.context";
import firebase from "../../api/firebase.api";

import DoneIcon from "@material-ui/icons/Done";
import TimerIcon from "@material-ui/icons/Timer";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const HeaderPanel = ({setOpenValidation, setOpenEdit, promise, company, isPending}) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [abstractUrl, setAbstractUrl] = useState(null);
	const [recuLibUrl, setRecuLibUrl] = useState(null);

	const {handleDelete} = useContext(ActionContext)
	const {user} = useContext(UserContext)

	useEffect(()=>{
		if("abstractStorageRef" in promise)
			firebase.storage().ref(promise.abstractStorageRef).getDownloadURL().then(setAbstractUrl)

		if("recuLibStorageRef" in promise)
			firebase.storage().ref(promise.recuLibStorageRef).getDownloadURL().then(setRecuLibUrl)

	}, [promise])

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleOpenPDF = (uriPDF) => event => {
		window.open(uriPDF, '_blank');
		handleClose()
	}

	const open = Boolean(anchorEl);

	const years = new Intl.DateTimeFormat("fr-FR", {year: "numeric"}).format(promise.dateTimeCreation)
	const icon = promise.state === "paid" ? <DoneIcon style={{verticalAlign: "sub", color: "#0e6245", fontSize: "1rem"}}/> : <TimerIcon style={{color: "orange", fontSize: "1rem"}}/>;

	const pdfDisabled = abstractUrl === null && recuLibUrl === null;
	const changeStateDisabled = !user.permissions.write || promise.state === "paid";

	return(
		<MDBRow className="card z-depth-0 border border-light mt-3" style={{padding: "2rem", backgroundColor: "inherit", flexWrap: "unset", display: "-webkit-box"}}>
			<MDBCol size="6">
				<h2 style={{fontSize: "1.8rem", marginBottom: "1rem"}}>{company.name} - {years}</h2>
				<MDBRow>
					<MDBCol size="2" className="mr-3 pl-3">
						<span className="mb-2" style={{color: "#646464"}}>Status</span>
						<MDBBadge color={promise.state === "paid" ? "success" : "warning"} style={{borderRadius: "5px", marginTop: "0.5rem"}} className="z-depth-0">
							<span style={{marginTop: "2px"}}>{promise.state === "paid" ? "Versée": "Non versée"}</span> {icon}
						</MDBBadge>
					</MDBCol>
					<MDBCol size="3" className="px-0 border-left border-light">
						<div className="ml-4">
							<span className="mb-1" style={{color: "#646464"}}>Date de creation</span>
							<br/>
							<span style={{marginTop: "0.4rem", display: "block"}}>
                                {new Intl.DateTimeFormat("fr-FR").format(promise.dateTimeCreation)}
                            </span>
						</div>
					</MDBCol>
					{"dateTimePayment" in promise && (
						<MDBCol size="4" className="px-0 border-left border-light">
							<div className="ml-4">
								<span className="mb-1" style={{color: "#646464"}}>Date de versement</span>
								<br/>
								<span style={{marginTop: "0.4rem", display: "block"}}>
                                {new Intl.DateTimeFormat("fr-FR").format(promise.dateTimePayment)}
                            </span>
							</div>
						</MDBCol>
					)}
				</MDBRow>
			</MDBCol>
			<MDBCol size="6">
				<MDBRow className="justify-content-end">
					<Button className="mr-4" color="primary" id="button-edit-ta" onClick={()=>{setOpenEdit(true)}} disabled={!user.permissions.write}>
						<MDBIcon icon="pen-alt" className="mr-2"/>
						Editer
					</Button>
					<Button color="secondary" id="button-delete-ta" onClick={handleDelete}>
						<MDBIcon icon="trash-alt" className="mr-2"/>
						Supprimer
					</Button>
				</MDBRow>
				<MDBRow className="mt-4 justify-content-end">
					<Button
						aria-controls="fade-menu"
						color="primary"
						aria-haspopup="true"
						onClick={handleClick}
						disabled={pdfDisabled}
					>
						Ouvrir fichier(s) PDF
						<ExpandMoreIcon/>
					</Button>
					<Menu
						id="fade-menu"
						anchorEl={anchorEl}
						keepMounted
						open={open}
						onClose={handleClose}
						TransitionComponent={Fade}
					>
						{abstractUrl !== null && (
							<MenuItem onClick={handleOpenPDF(abstractUrl)}>
								<MDBIcon icon="file-invoice" className="mr-2"/> Résumé de la promesse
							</MenuItem>
						)}

						{recuLibUrl !== null && (
							<MenuItem onClick={handleOpenPDF(recuLibUrl)}>
								<MDBIcon icon="file-contract" className="mr-2"/> Reçu libératoire
							</MenuItem>
						)}
					</Menu>
					<Button
						className="badge-success ml-4"
						disabled={changeStateDisabled || isPending}
						onClick={()=>{setOpenValidation(true)}}
					>
						Valider
						<DoneIcon className="ml-2" style={{color: "#0e6245", fontSize: "1rem"}}/>
						{isPending ? (<MDBIcon icon="spinner" className="ml-1" pulse fixed />) : ""}
					</Button>
				</MDBRow>
			</MDBCol>
		</MDBRow>
	)
}

export default HeaderPanel