import React, {useContext, useEffect, useState} from 'react';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import ActionContext from "../../context/action.context";
import * as StringUtils from "../../utils/StringUtils";

export default function AffectationDialog({open, affectation, editMode, handleClose}) {
	const [name, setName] = useState(editMode ? affectation.name : "")
	const [isValid, setIsValid] = useState(false)

	const {handleEdit, handleCreate} = useContext(ActionContext)

	const handleSubmit = async () => {
		if(editMode){
			handleEdit({name: name})
		} else {
			handleCreate({
				affectationId: `Affectation-${StringUtils.generateUuid()}`,
				name: name
			})
		}

		handleClose()
	}

	useEffect(()=>{
		let localValid = name !== "";

		if(editMode){
			localValid = localValid && name !== affectation.name
		}

		setIsValid(localValid)
	}, [name, affectation, editMode])

	return (
		<Dialog
			fullWidth={true}
			maxWidth={"xs"}
			open={open}
			onClose={handleClose}
			aria-labelledby="form-dialog-title"
		>
			<DialogTitle id="form-dialog-title">{editMode ? "Editer" : "Ajouter"} une structure</DialogTitle>
			<DialogContent>
				<TextField
					onChange={(event)=>{setName(event.target.value)}}
					value={name}
					autoFocus
					margin="dense"
					label="Nom de l'affectation"
					type="text"
					fullWidth
					id="input-affectation-name"
					className="mb-3"
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color="secondary">
					Annuler
				</Button>
				<Button onClick={handleSubmit} color="primary" disabled={!isValid}>
					{editMode ? "Modifier" : "Ajouter"}
				</Button>
			</DialogActions>
		</Dialog>
	);
}