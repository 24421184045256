import React, {useRef, useState} from 'react';

import Button from "@material-ui/core/Button";
import CompanyForm from "../form/company.form";

export default function CompanyStep({companyProp, contactDecisionProp, contactCertificateProp, handleChangeFormState, handleNextStep}){
    const [isValid, setIsValid] = useState(false)
    const companyFormRef = useRef()

    const handleIsValid = (isValidLocal) => {setIsValid(isValidLocal)}

    const handleSubmit = async ({company, contactDecision, contactCertificate}) => {
        handleChangeFormState("company")(company)
        handleChangeFormState("contactDecision")(contactDecision)
        handleChangeFormState("contactCertificate")(contactCertificate)
        handleNextStep()
    }

    return(
        <div style={{marginTop: "8vh", overflowY: "scroll", maxHeight: "85vh", overflowX: "hidden"}} className="px-1">
            <div className="font-weight-bold pl-0 my-4">
                <h3 className="my-0"><strong>Coordonnées de l'entreprise</strong></h3>
                <p className="mt-2">nécessaires à l’édition de votre reçu libératoire</p>
            </div>

            <CompanyForm
                ref={companyFormRef}
                handleSubmit={handleSubmit}
                companyProp={companyProp}
                contactDecisionProp={contactDecisionProp}
                contactCertificateProp={contactCertificateProp}
                handleIsValid={handleIsValid}
                isEdit={false}
            />

            <div className="w-100 text-center mt-5">
                <Button
                    id="company-form-button"
                    color="primary"
                    size="large"
                    style={{paddingRight: "2rem", paddingLeft: "2rem"}}
                    disabled={!isValid}
                    onClick={() => companyFormRef.current.submit()}
                >
                    Suivant
                </Button>
            </div>
        </div>
    )
}