import firebase from '../api/firebase.api.js'

const get = (organisationId) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.get()
		.then(doc=>doc.data())
		.catch(error=>Promise.reject(error.message))
)

const getConfig = (organisationId, configDoc) => (
	firebase
		.firestore()
		.collection("Organisation")
		.doc(organisationId)
		.collection("Config")
		.doc(configDoc)
		.get()
		.then(doc=> doc.data())
		.catch(error=>Promise.reject(error.message))
)


export {
	get,
	getConfig
}