import React from 'react';
import {MDBBadge} from 'mdbreact';
import {useHistory} from 'react-router-dom'

import DoneIcon from "@material-ui/icons/Done";
import TimerIcon from "@material-ui/icons/Timer";
import CloseIcon from '@material-ui/icons/Close';

export default function CompanyRow({company, promiseList}){
    const history = useHistory()

    const handleClick = () => {
        history.push(`/company/view/${company.companyId}`, {company: company});
    }

    let stateBadge = (
        <MDBBadge color="light" style={{borderRadius: "5px", marginTop: "0.5rem"}} className="z-depth-0">
            <span style={{marginTop: "2px", fontSize: "1.3em"}}>Non déclarée</span>
            <CloseIcon style={{verticalAlign: "sub", color: "#000000", fontSize: "1rem", marginLeft: "0.15rem"}}/>
        </MDBBadge>
    )

    let amount = 0

    if(promiseList.length > 0) {
        promiseList.sort((item1, item2) => item2.dateTime - item1.dateTime);

        const lastPromise = promiseList[0];

        const icon = lastPromise.state === "paid" ?
            <DoneIcon style={{verticalAlign: "sub", color: "#0e6245", fontSize: "1rem", marginLeft: "0.15rem"}}/> :
            <TimerIcon style={{verticalAlign: "sub", color: "orange", fontSize: "1rem", marginLeft: "0.15rem"}}/>;

        stateBadge = (
            <MDBBadge color={lastPromise.state === "paid" ? "success" : "warning"} style={{borderRadius: "5px", marginTop: "0.5rem"}} className="z-depth-0">
                <span style={{marginTop: "2px", fontSize: "1.3em"}}>{lastPromise.state === "paid" ? "Versée" : "Non-versée"}</span> {icon}
            </MDBBadge>
        )

        amount = lastPromise.amount
    }

    const {contactDecision} = company

    return(
        <tr onClick={handleClick} className="tr-hover">
            <td>{company.name}</td>
            <td>
                <span>{contactDecision.firstname} {contactDecision.lastname} - {contactDecision.position}</span>
            </td>
            <td>{contactDecision.email}</td>
            <td>{contactDecision.phoneNumber}</td>
            <td>{stateBadge}</td>
            <td>{amount}</td>
        </tr>
    )
}