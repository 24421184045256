import React from "react";
import {Redirect, Route, Switch} from 'react-router-dom';

import CompanyDashboard from '../pages/dashboard/company.dashboard';
import PromiseDashboard from '../pages/dashboard/promise.dashboard';

import CompanyView from '../pages/view/company.view';
import PromiseView from "../pages/view/promise.view";

import AffectationDashboard from "../pages/dashboard/affectation.dashboard";
import StatsDashboard from "../pages/dashboard/stats.dashboard";

const ConnectedRouter = () => {
    return (
        <Switch>
            <Route path='/' exact component={()=>(<Redirect to="/dashboard/ta"/>)}/>

            <Route path='/dashboard/versement' exact component={PromiseDashboard}/>
            <Route path='/dashboard/entreprise/' exact component={CompanyDashboard}/>
            <Route path='/dashboard/affectation/' exact component={AffectationDashboard}/>
            <Route path='/dashboard/stats/' exact component={StatsDashboard}/>

            <Route path='/company/view/:companyId' exact component={CompanyView}/>
            <Route path='/promise/view/:promiseId' exact component={PromiseView}/>

            <Route>
                404
            </Route>
        </Switch>
    );
};

export default ConnectedRouter;
