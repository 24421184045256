class ListUtils {
    static split(list, nbByRow){
        let newList = [];
        let newSubList = [];
        list.forEach((item, index)=>{
            index = index+1;
            newSubList.push(item);
            if(index>=nbByRow && index%nbByRow === 0){
                newList.push(newSubList);
                newSubList = [];
            }
        });

        if(newSubList.length > 0)
            newList.push(newSubList);

        return newList;
    }

    static filterSearch(list, propFilter, value){
        return list.filter((element)=>element[propFilter].toUpperCase().startsWith(value.toUpperCase()));
    }

    static filterSearchContain(list, propFilter, value){
        return list.filter((element)=>element[propFilter].toUpperCase().includes(value.toUpperCase()));
    }

    static filterValue(list, propFilter, values){
        return list.filter((element)=>values.includes(element[propFilter]));
    }

    static sort(list, propSorter, value){
        list.sort((element1, element2) => {return parseFloat(element1[propSorter]) - parseFloat(element2[propSorter])});

        if(value === "decroissant")
            list.reverse();

        return list
    }
}

export default ListUtils;