import React, {useContext} from "react";

import {useTheme} from "@material-ui/core/styles";

import {MDBAnimation, MDBCol, MDBRow} from "mdbreact";

import {NumberToLetter} from "convertir-nombre-lettre";
import DataContext from "../../context/data.context";

export default function ResumeStep({promiseInfo}){
	const {organisation} = useContext(DataContext);
	const theme = useTheme();

	const {contact} = organisation.contact;

	let amountLetters = promiseInfo.amount.toString()
	if(amountLetters.includes(","))
		amountLetters = amountLetters.split(",");
	else
		amountLetters = [amountLetters, "00"]

	amountLetters = `${NumberToLetter(amountLetters[0])} euros et ${NumberToLetter(amountLetters[1])} centimes`

	return(
		<div style={{marginTop: "15vh"}}>
			<MDBRow className="justify-content-center">
				<MDBCol md="11" style={{overflow: "auto"}}>
					<MDBAnimation type="fadeIn">
						<h2>Votre promesse de versement a bien été enregistrée</h2>
						<p style={{fontSize: "1.2em", fontWeight: 500}} dangerouslySetInnerHTML={{__html: organisation.promiseConfig.savedHtml}}>
						</p>

						{promiseInfo.paymentType === "bank_transfert" && (
							<div className="mt-2">
								<p style={{fontSize: "1rem", fontWeight: 400}}>
									Vous devez effectuer un virement bancaire d'une valeur de {promiseInfo.amount}€ à l'organisation
								</p>
								<img src={organisation.ribUrl} className="mt-3" alt={`rib-${organisation.name}`}/>
							</div>
						)}

						{promiseInfo.paymentType === "check" && (
							<>
								<p style={{fontSize: "1rem", fontWeight: 400}}>
									Vous devez envoyer un chèque à l'organisation d'un montant de {promiseInfo.amount}€
								</p>
								<MDBRow className="py-4" style={{border: `2px solid ${theme.palette.primary.main}`}}>
									<MDBCol size="4" style={{fontWeight: "bold", display: "grid"}}>
										<span style={{marginBottom: "1rem"}}>Montant :</span>
										<span style={{marginBottom: "1rem"}}>Montant en toutes lettres :</span>
										<span style={{marginBottom: "1rem"}}>Ordre :</span>
										<hr/>
										<span style={{marginBottom: "1rem"}}>Nom de l'organisation :</span>
										<span style={{marginBottom: "1rem"}}>Adresse :</span>
										<span>Contact :</span>
									</MDBCol>
									<MDBCol size="8" style={{fontWeight: "500", display: "grid"}}>
										<span>{promiseInfo.amount} €</span>
										<span>{amountLetters}</span>
										<span>{organisation.checkOrder}</span>
										<hr/>
										<span>{organisation.name}</span>
										<span>{organisation.location.address} - {organisation.location.postalCode} {organisation.location.city}</span>
										<span>{contact.firstname} {contact.lastname} - {contact.position}</span>
									</MDBCol>
								</MDBRow>
							</>
						)}
					</MDBAnimation>
				</MDBCol>
			</MDBRow>
		</div>
	)
}