import React, {useContext, useEffect, useState} from 'react';
import {MDBAnimation, MDBCol, MDBRow, MDBTable, MDBTableBody, MDBTableHead} from 'mdbreact';
import Button from "@material-ui/core/Button";

import firebase from '../../api/firebase.api'

import PromiseRow from "../../components/row/promise.row";
import ListUtils from "../../utils/ListUtils";
import FilterSearch from "../../components/FilterSearch";
import FilterSelect from "../../components/FilterSelect";

import '../../assets/css/dashboard.css'
import DataContext from "../../context/data.context";

export default function DashboardPromise(){
    const [filterSearch, setFilterSearch] = useState("")
    const [filterState, setFilterState] = useState("All")
    const [sort, setSort] = useState("dateTimeCreation-decroissant")

    const [promiseList, setPromiseList] = useState([])

    const [promiseListDisplayed, setPromiseListDisplayed] = useState([])

    const {promiseRef} = useContext(DataContext)

    const generateCSV = async () => {
        function downloadBlob(dataUrl, name = `Taxe_Apprentissage_${dateLocal}.xlsx`) {
            const link = document.createElement('a');
            link.href = dataUrl;
            link.download = name;

            link.dispatchEvent(
                new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    view: window
                })
            );

            setTimeout(() => {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(dataUrl);
                link.remove();
            }, 100);
        }

        const generateExcel = firebase.functions().httpsCallable("generateExcel")
        const {data} = await generateExcel()

        let dateLocal = new Intl.DateTimeFormat("fr-FR", {day: "2-digit", month: "2-digit", year: "numeric"}).format(Date.now()).replaceAll("/", "-")

        downloadBlob(`data:application/octet-stream;base64,${data}`);
    }

    useEffect(()=>{
        const unsubscribe = promiseRef.onSnapshot((snap)=>{
            const listData = snap.docs.map(doc=>doc.data())
            console.log(listData)
            setPromiseList(listData)
        })

        return () => {unsubscribe()}
    }, [promiseRef])
    useEffect(()=>{
        let list = promiseList
        if(filterSearch !== "")
            list = ListUtils.filterSearch(promiseList, "companyName", filterSearch);

        let filterStateArray = [filterState]
        if(filterState === "All")
            filterStateArray.push("pending", "paid")

        list = ListUtils.filterValue(list, "state", filterStateArray);

        const sortData = sort.split("-");
        list = ListUtils.sort(list, sortData[0], sortData[1])
        setPromiseListDisplayed(list)

    }, [filterState, filterSearch, sort, promiseList])

    return(
        <div className="dashboard" id="dashboard-ta">
            <div className="dashboard-header mb-3">
                <MDBRow>
                    <MDBCol size="9" className="pl-0">
                        <MDBRow>
                            <MDBCol size="4">
                                <FilterSearch
                                    placeholder="Rechercher par nom de l'entreprise"
                                    value={filterSearch}
                                    handle={setFilterSearch}
                                />
                            </MDBCol>
                            <MDBCol size="2">
                                <FilterSelect
                                    label="Status"
                                    value={filterState}
                                    handle={setFilterState}
                                    options={[
                                        {text: "Tout", value: "All"},
                                        {text: "Non versée", value: "pending"},
                                        {text: "Versée", value: "paid"},
                                    ]}
                                />
                            </MDBCol>
                            <MDBCol size="3" style={{paddingRight: "10px"}}>
                                <FilterSelect
                                    label="Trier par"
                                    value={sort}
                                    handle={setSort}
                                    options={[
                                        {text: "Date (Décroissant)", value: "dateTimeCreation-decroissant"},
                                        {text: "Montant (Décroissant)", value: "amount-decroissant"},
                                        {text: "Date (Croissant)", value: "dateTimeCreation-croissant"},
                                        {text: "Montant (Croissant)", value: "amount-croissant"},
                                    ]}
                                />
                            </MDBCol>
                            <MDBCol size="2" style={{paddingRight: "10px", marginTop: "1.5rem", marginLeft: "1rem"}}>
                                <Button color="primary" onClick={generateCSV}>
                                    Export CSV
                                </Button>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            </div>
            <div className="dashboard-content card z-depth-0dot5" style={{padding: "1rem"}}>
                <MDBAnimation type="fadeIn">
                    <MDBTable className="table-head-bold" scrollY={true} maxHeight={"65vh"} style={{padding: "0.55rem"}}>
                        <MDBTableHead color="primary">
                            <tr>
                                <th style={{paddingLeft: "2rem"}}>Statut</th>
                                <th>Nom entreprise</th>
                                <th>Montant</th>
                                <th>Type paiement</th>
                                <th>Date de création</th>
                                <th/>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {promiseListDisplayed.map((promise, index)=>(
                                <PromiseRow
                                    promise={promise}
                                    key={index}
                                    filterState={{
                                        filter: [{type: "state", value:filterState}],
                                        orderBy: sort,
                                    }}
                                />
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </MDBAnimation>
            </div>
        </div>
    )
}