import React, {useContext, useState} from 'react';
import {Button, Typography} from "@material-ui/core";
import {MDBIcon} from 'mdbreact';

import AffectationDialog from "../dialog/affectation.dialog";

import {warningDelete} from "../../utils/Alert";
import ActionContext from "../../context/action.context";

export default function AffectationRow({promiseList, affectation}){
    const sum = promiseList.reduce((sum,ta)=>sum+parseFloat(ta.amount), 0);

    return(
        <tr>
            <td>
                <div className="d-block" style={{maxWidth: "20vw"}}>
                    <Typography noWrap={true}>
                        {affectation.name}
                    </Typography>
                </div>
            </td>
            <td>{promiseList.length}</td>
            <td>{sum} €</td>
            <ActionTd affectation={affectation}/>
        </tr>
    )
}

const ActionTd = ({affectation}) => {
    const [open, setOpen] = useState(false)

    const {handleDelete} = useContext(ActionContext)

    const handleDeleteAffectation = () => {
        warningDelete().then(()=>{
            handleDelete(affectation.affectationId)
        })
    }

    return(
        <td style={{textAlign: "center"}} className="btn-action">
            <Button size="medium" className="mr-2" onClick={()=>{setOpen(true)}} color="primary">
                <MDBIcon icon="pen-alt" className="mr-2"/>
                Editer
            </Button>
            <Button color="secondary" size="medium" onClick={handleDeleteAffectation}>
                <MDBIcon icon="trash-alt" className="mr-2"/>
                Supprimer
            </Button>
            <AffectationDialog
                affectation={affectation}
                editMode={true}
                open={open}
                handleClose={()=>{setOpen(false)}}
            />
        </td>
    )
}