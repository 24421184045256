import React, {useEffect, useState} from 'react'
import {MDBCol, MDBRow, MDBSpinner} from 'mdbreact'
import {useParams} from 'react-router-dom'

import ErrorBox from "../components/ErrorBox";
import PromiseForm from "../components/form/promise.form";

import DataContext from "../context/data.context";
import * as OrganisationModel from "../model/organisation.model";

import stackdNewLogo from "../assets/img/stackd-logo-title.png";
import firebase from '../api/firebase.api'

export default function PromisePage(){
    const { organisationId } = useParams();
    const [organisation, setOrganisation] = useState(null)
    const [error, setError] = useState(null)

    useEffect(()=>{
        OrganisationModel.get(organisationId).then(setOrganisation).catch(setError)
    }, [organisationId])

    useEffect(()=>{
        if(organisation === null || organisation.hasOwnProperty("promiseConfig"))
            return;

        Promise.all([
            firebase.storage().ref(organisation.ribStorageRef).getDownloadURL(),
            firebase.storage().ref(organisation.logoStorageRef).getDownloadURL(),
            OrganisationModel.getConfig(organisationId, "promisePage"),
        ]).then((result)=>{
            setOrganisation({...organisation, ...{ribUrl: result[0], logoUrl: result[1], promiseConfig: result[2]}})
        }).catch(setError)
    }, [organisation, organisationId])

    const years = new Intl.DateTimeFormat("fr-FR", {year: "numeric"}).format(Date.now())

    if(organisation == null && error == null)
        return <MDBSpinner/>

    if(error)
        return <ErrorBox error={error}/>

    if(!organisation.hasOwnProperty("logoUrl") || !organisation.hasOwnProperty("ribUrl"))
        return <MDBSpinner/>

    return(
        <section id="promise-ta">
            <MDBRow className="mx-0" style={{height: "100vh"}}>
                <MDBCol size="5" style={{backgroundColor: "white"}}>
                    <h3 style={{color: "#E02020", fontFamily: "Montserrat", marginTop: "5rem", marginLeft: "3rem"}}>
                        <img src={stackdNewLogo} alt="logo-stackd" className="ml-3" width="40%"/>
                    </h3>

                    <div className="text-center" style={{marginTop: "10rem"}}>
                        <h1 style={{fontSize: "3em"}}>Versez le solde de votre taxe d'apprentissage {years}</h1>
                    </div>
                </MDBCol>
                <MDBCol size="7">
                    <DataContext.Provider value={{organisation}}>
                        <PromiseForm/>
                    </DataContext.Provider>
                </MDBCol>
            </MDBRow>
        </section>
    )
}