import * as CompanyModel from "./company.model";
import * as PromiseModel from "./promise.model";

const getStats = (organisationId, promiseRef, selectedYear) => {
	return new Promise(async (resolve, reject) => {
		try {
			const result = await Promise.all([
				CompanyModel.getAll(organisationId),
				promiseRef.get(),
				PromiseModel.getRef(organisationId, parseInt(selectedYear) - 1).get()
			]);

			const companyList = result[0].docs.map(doc => doc.data())
			const promiseList = result[1].docs.map(doc => doc.data())
			const promisePreviousYearList = result[2].docs.map(doc => doc.data())

			const companyIdPreviousYearList = promisePreviousYearList.map(promise => promise.companyId)

			const promisePaidList = promiseList.filter(ta => ta.state === "paid")
			const promisePendingList = promiseList.filter(ta => ta.state === "pending")

			const totalAmount = round(promiseList.map(getAmount).reduce((total, amount) => total + amount, 0))
			const totalAmountPaid = round(promisePaidList.map(getAmount).reduce((total, amount) => total + amount, 0))
			const totalAmountPending = round(promisePendingList.map(getAmount).reduce((total, amount) => total + amount, 0))

			const averageAmount = round(totalAmount / promiseList.length)

			const promisePaidAmount = promisePaidList.map(getAmount)
			const promisePendingAmount = promisePendingList.map(getAmount)

			const promiseDistribution = {
				less500: {
					paid: round(promisePaidAmount.filter(amount => amount < 500).length / promiseList.length),
					pending: round(promisePendingAmount.filter(amount => amount < 500).length / promiseList.length)
				},
				between500and1000: {
					paid: round(promisePaidAmount.filter(amount => amount >= 500 && amount <= 1000).length / promiseList.length),
					pending: round(promisePendingAmount.filter(amount => amount >= 500 && amount <= 1000).length / promiseList.length)
				},
				more1000: {
					paid: round(promisePaidAmount.filter(amount => amount > 1000).length / promiseList.length),
					pending: round(promisePendingAmount.filter(amount => amount > 1000).length / promiseList.length)
				}
			};

			console.log("promiseDistribution", promiseDistribution)

			const retentionRate = (
				(promiseList.filter(promise => companyIdPreviousYearList.includes(promise.companyId)).length / companyIdPreviousYearList.length) * 100
			)
			const newCaptionRate = (
				(promiseList.filter(promise => !companyIdPreviousYearList.includes(promise.companyId)).length / promiseList.length) * 100
			)

			resolve({
				totalAmount: {
					global: totalAmount,
					paid: totalAmountPaid,
					pending: totalAmountPending
				},
				counter: {
					company: companyList.length,
					companyPaid: promisePaidList.length,
					companyPending: promisePendingList.length
				},
				average: averageAmount,
				promiseDistribution: promiseDistribution,
				rate: {
					retention: retentionRate,
					newCompany: newCaptionRate
				}
			})
		} catch(error){
			reject(error)
		}
	})

}

const round = (num) => {
	return Math.round((num + Number.EPSILON) * 100) / 100
}
const getAmount = (promise) => parseFloat(promise.amount.replace(",", "."))

export {
	getStats,
	round
}

