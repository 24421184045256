import React from "react";
import {useHistory, useLocation} from 'react-router-dom'
import {makeStyles} from "@material-ui/core/styles";
import ListItem from "@material-ui/core/ListItem";

import '../assets/css/side-drawer.css'

export default function SideBarLink({permissionsListAllowed, icon, to, children}){
    let history = useHistory();
    let location = useLocation();

    const useStyles = makeStyles((theme) => ({
        drawerItem: {
            marginBottom: "1rem",
            paddingLeft: "2rem",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            fontWeight: 400,
            color: "#79869F"
        },
        drawerItemActive: {
            marginBottom: "1rem",
            paddingLeft: "2rem",
            color: "#2979F2",
            backgroundColor: "rgba(41, 121, 242, 0.1)",
            paddingTop: "1rem",
            paddingBottom: "1rem",
            fontWeight: 400,
        },
    }))
    const classes = useStyles();

    return(
        <ListItem
            button
            onClick={()=>{history.push(to)}}
            alignItems={'center'}
            selected={location.pathname === to}
            className={location.pathname === to ? classes.drawerItemActive : classes.drawerItem}
        >
            {children}
        </ListItem>
    )
}