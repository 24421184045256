import React, {useContext, useEffect, useState} from "react";
import {useParams} from 'react-router-dom'

import {MDBCol, MDBRow, MDBSpinner} from "mdbreact";

import CompanyDialog from "../../components/dialog/company.dialog";

import ActionContext from "../../context/action.context";
import ToastContext from "../../context/toast.context";
import UserContext from "../../context/user.context";

import {error} from "../../utils/Alert";
import HeaderPanel from "../../components/company-view/header.panel";
import ContactPanel from "../../components/company-view/contact.panel";
import ObjectUtils from "../../utils/ObjectUtils";


export default function CompanyView(){
    const { companyId } = useParams();

    const [open, setOpen] = useState(false)

    const [company, setCompany] = useState(null);

    const {toast} = useContext(ToastContext)
    const {user, organisationRef} = useContext(UserContext)

    useEffect(()=>{
        if(company !== null)
            return;

        const unsubscribe = organisationRef
            .collection("Company")
            .doc(companyId)
            .onSnapshot((snap)=>{
                const companyData = snap.data()
                setCompany(companyData)
            })

        return () => {unsubscribe()}
    },[company, companyId, user, organisationRef])

    const handleEdit = (companyChanged) => {
        organisationRef
            .collection("Company")
            .doc(companyId)
            .update(companyChanged)
            .then(()=>{
                toast.fire({title: "Modification validée", icon: "success"})
                setOpen(false)
            })
            .catch(error)
    }
    const handleDelete = () => {
        organisationRef
            .collection("Company")
            .doc(companyId)
            .delete()

        organisationRef
            .collection("Promise")
            .where("companyId", "==", companyId)
            .delete()
    }

    if(company === null)
        return (
            <div className="mt-5">
                <MDBSpinner/>
            </div>
        )

    const {contactDecision, contactCertificate} = company;
    let contactDecisionPanelTitle = ObjectUtils.equal(contactDecision, contactCertificate) ? "Contact décision & attestation" : "Contact décision"

    return(
        <ActionContext.Provider value={{handleEdit, handleDelete}}>
            <div id="view-ta" className="view-content">
                <MDBRow>
                    <MDBCol size="12">
                        <HeaderPanel company={company} handleOpenEdit={()=>{setOpen(true)}}/>
                        <ContactPanel title={contactDecisionPanelTitle} contact={contactDecision}/>
                        {!ObjectUtils.equal(contactDecision, contactCertificate) && (
                            <ContactPanel title="Contact attestation" contact={contactCertificate}/>
                        )}
                    </MDBCol>
                </MDBRow>
            </div>
            <CompanyDialog
                companyProp={company}
                open={open}
                handleClose={()=>{setOpen(false)}}
            />
        </ActionContext.Provider>
    )
}