import {MDBFormInline, MDBIcon} from "mdbreact";
import React from "react";

export default function FilterSearch({placeholder, handle, value}){
	return(
		<MDBFormInline className="md-form" style={{marginBottom: "0.5rem"}}>
			<MDBIcon icon="search" fal={true} />
			<input
				style={{
					paddingTop: "0.5rem",
					width:"80%",
					paddingBottom: "0.5rem"
				}}
				id="input-filter-search"
				value={value}
				className="form-control form-control-md ml-3"
				type="text"
				placeholder={placeholder}
				onChange={(event)=>{handle(event.target.value)}}
				aria-label="Search"
			/>
		</MDBFormInline>
	)
}